import { removeMask } from "@utils/masks/phone"

export async function sendDataToLeadsCenter(data, campaignData) {
  const request = {
    name: data.firstName,
    surname: data.lastName,
    email: data.email,
    phone_number: removeMask(data.phone),
    form_conversion_id: data.formId || 10,
    pipeline_id: 1,
    utm_content: campaignData.utm_content,
    utm_term: campaignData.utm_term,
    utm_campaign: campaignData.utm_campaign,
    utm_medium: campaignData.utm_medium,
    utm_source: campaignData.utm_source,
    gclid: campaignData.gclid,
    hsa_grp: campaignData.hsa_grp,
    hsa_src: campaignData.hsa_src,
    hsa_ad: campaignData.hsa_ad,
    hsa_mt: campaignData.hsa_mt,
    hsa_tgt: campaignData.hsa_tgt,
    hsa_kw: campaignData.hsa_kw,
    hsa_net: campaignData.hsa_net,
    hsa_cam: campaignData.hsa_cam,
    hsa_ver: campaignData.hsa_ver,
    hsa_acc: campaignData.hsa_acc,
    MSCKLID: campaignData.MSCKLID,
    fbclid: campaignData.fbclid,
    firstpageaccessed: sessionStorage.getItem("aquisition_page"),
  }

  const headers = new Headers()
  headers.append("Content-Type", "application/json")

  fetch(`${process.env.GATSBY_LEADS_CENTER_URL}/contacts/x-apps/create`, {
    method: "post",
    headers: headers,
    body: JSON.stringify(request),
  })
}
