import { getTrackingId } from "./../utils/getTrackingId"

export async function sendFormToMautic(formId, data) {
  const formData = new FormData()

  for (const name in data) {
    data[name] && formData.append(name, data[name])
  }

  return fetch(`https://m.x-apps.com.br/form/submit?formId=${formId}`, {
    method: "post",
    body: formData,
    mode: "no-cors",
  })
}

export async function sendDataToMautic(formId, data, campaignData, firstPage) {
  const formData = new FormData()

  const formattedData = {
    "mauticform[f_name]": data.firstName,
    "mauticform[lastname]": data.lastName,
    "mauticform[email]": data.email,
    "mauticform[phone]": data.phone,
    "mauticform[formId]": formId,
    "mauticform[utm_content]": campaignData.utm_content,
    "mauticform[utm_term]": campaignData.utm_term,
    "mauticform[utm_campaign]": campaignData.utm_campaign,
    "mauticform[utm_medium]": campaignData.utm_medium,
    "mauticform[utm_source]": campaignData.utm_source,
    "mauticform[gclid]": campaignData.gclid,
    "mauticform[fbclid]": campaignData.fbclid,
    "mauticform[MSCKLID]": campaignData.MSCKLID,
    "mauticform[firstpageaccessed]": sessionStorage.getItem("aquisition_page"),
    "mauticform[tracking_id]": getTrackingId()
  }

  for (const name in formattedData) {
    formattedData[name] && formData.append(name, formattedData[name])
  }

  return fetch(`https://m.x-apps.com.br/form/submit?formId=${formId}`, {
    method: "post",
    body: formData,
    mode: "no-cors",
  })
}
