import routes from "@utils/routes"
import React from "react"
import { manageCookies } from "../manageCookies"

export const useOnExitIntent = (callback, shouldBlock = true) => {
  const pagesToBlock = [routes.contato, routes.vagas]
  const urlFragmentsToPreventShowing = ["?utm_campaign=newsletter"]

  const handleMouseMove = e => {
    if (typeof window !== "undefined") {
      const fromBlockedUrlFragments = urlFragmentsToPreventShowing.includes(
        window.location.search
      )
      const fromBlockedPages = pagesToBlock.includes(window.location.pathname)

      if (
        shouldBlock &&
        (fromBlockedUrlFragments ||
          fromBlockedPages ||
          !!manageCookies.getCookies("exitIntent") ||
          !!manageCookies.getCookies("exitIntentNewsletter") ||
          !!manageCookies.getCookies("utm_campaign"))
      ) {
        return
      }
      if (!e.toElement && !e.relatedTarget && e.offsetY < 1) {
        callback()
      }
    }
  }

  React.useEffect(() => {
    document.addEventListener("mouseout", handleMouseMove)
    return () => {
      document.removeEventListener("mouseout", handleMouseMove)
    }
  }, [])
}
