import XAppSvg from "@images/xapps.svg"
import React from "react"
import * as Style from "./Logo.styles"

function Logo(props) {
  return (
    <Style.Wrapper {...props}>
      <XAppSvg />
    </Style.Wrapper>
  )
}

export default Logo
