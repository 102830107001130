import Cookies from 'js-cookie';

export const manageCookies = {
  setExpiresInSeconds(seconds = 3600) {
    let date = new Date(new Date().getTime() + seconds * 1000);
    Cookies.set('exitIntentNewsletter', true, {
      expires: date
    });
  },
  setExpiresExitIntentCookies(days = 1) {
    let date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    document.cookie = `exitIntent=true;expires=${date};path="/"`
  },
  getCookies(name) {
    const cookieName = `${name}=`
    const cookieSplitter = document.cookie.split(";")

    for (let i = 0; i < cookieSplitter.length; i++) {
      let c = cookieSplitter[i]
      while (c.charAt(0) === " ") c = c.substring(1, c.length)
      if (c.indexOf(cookieName) === 0)
        return c.substring(cookieName.length, c.length)
    }

    return null
  },
}
