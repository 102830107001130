import React from "react"
import { Box } from "rebass"

export const Wrapper = ({ ...props }) => (
  <Box
    {...props}
    sx={{
      svg: {
        ...props.sx?.svg,
        display: "block",
      },
    }}
  />
)
