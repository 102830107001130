import { useIntl } from "gatsby-plugin-intl"
import { size, trim } from "lodash"
import { useState } from "react"

const PT_BR_LOCALE = "pt-BR"

export function usePhoneMask() {
  const { locale } = useIntl()
  const [mask, setMask] = useState(
    locale === PT_BR_LOCALE ? "(99) 9999-9999?" : "+99999999999999"
  )

  function handleMaskChange(e) {
    const numbersFilled = size(trim(e.target.value, "_"))

    if (numbersFilled === 15 && locale === PT_BR_LOCALE) {
      setMask("(99) 99999-9999")
    }
  }

  return [mask, handleMaskChange]
}

export function removeMask(maskedPhone) {
  return maskedPhone.replace(/[^\d]+/g, "")
}

export function addCountryCode(phone, locale) {
  return `${locale === PT_BR_LOCALE ? "55" : ""}${phone}`
}
