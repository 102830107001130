import { removeMask } from "@utils/masks/phone"
import routes from "@utils/routes"
import { navigate } from "gatsby-plugin-intl"

export const goToThanksPage = values => {
  navigate(
    `${routes.obrigado}?${new URLSearchParams({
      name: values.firstName,
      surname: values.lastName,
      email: values.email,
      phone: removeMask(values.phone),
    }).toString()}`
  )
}
